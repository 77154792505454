import React, { Component } from 'react';
import BlogPage from './blog-page/blog-page';

import './blog.scss';

class Blog extends Component {
    render(): React.ReactNode {
        return (
            <div className={'blog container'}>
                <BlogPage/>
            </div>
        );
    }
}

export default Blog;
