import React, { Component } from 'react';
import './blog-toc.scss';

interface props {}

interface conditions {
    data: any
}

class BlogToc extends Component<props, conditions> {

    constructor(props: any) {
        super(props);

        this.state = {
            data: []
        };

        this.cToc = this.cToc.bind(this);
    }

    cToc() {
        const blogPost = document.getElementById('single-post');
        var toc = [];

        if (!!(blogPost)) {
            var contents = blogPost.children;

            if (contents.length > 0) {
                for (var i=1,n=contents.length;i<n;i++) {
                    var str = contents[i].localName;

                    if ((str.match(/^h\d{1}$/gi))
                        && (!!(contents[i].id))) {

                        toc.push({
                            linkId: contents[i].id,
                            linkText: contents[i].textContent
                        });
                    }
                }

                this.setState({data: toc});
            }
        }
    }

    componentDidMount(): void {
        this.cToc();
    }

    render() {
        return (
            <aside className={'col-md-2'}>
                <div className={'position-sticky c-top-4'}>
                    <nav className={'pb-4'} id={'toc'} data-toggle="toc">
                        <p className={'font-weight-bold text-muted text-uppercase'}>
                                <small>Table of contents</small>
                        </p>
                        <ul className={'nav navbar-nav mr-2'}>
                            {this.state.data.map((value:any, index:any) => {
                                return (
                                    <li className="nav-item">
                                        <a className="nav-link" href={'#' + value.linkId}>{value.linkText}</a>
                                    </li>
                                );
                            })}
                        </ul>
                    </nav>
                </div>
            </aside>
        );
    }
}

export default BlogToc;
