import React, {Component} from 'react';
import Blog from './blog/blog';

import './app.scss';

interface props {
}

class App extends Component<props> {

    constructor(props: any) {
        super(props);

        this.handleClick = this.handleClick.bind(this);
        this.handleScroll = this.handleScroll.bind(this);
    }

    componentDidMount(): void {
        window.addEventListener('click', this.handleClick);
        window.addEventListener('scroll', this.handleScroll);
    }

    componentWillUnmount(): void {
        window.removeEventListener('click', this.handleClick);
        window.removeEventListener('scroll', this.handleScroll);
    }

    handleClick(event: any) {
        console.log(event);
        //
    }

    handleScroll(event: any) {
        let curScrollPos = window.scrollY;
        let list = document.querySelectorAll('li.nav-item a.nav-link');

        list.forEach(function (value, index) {
            let hash: any = value.getAttribute('href');
            let li: any = document.querySelector(hash);
            let liTop: number = li.getBoundingClientRect().top + window.pageYOffset;

            if ((hash !== undefined || li !== undefined || liTop !== undefined) && (liTop <= curScrollPos)) {
                for (let i = 0, n = list.length; i < n; i++) {
                    if (value !== list[i]) {
                        list[i].classList.remove('active');
                    } else {
                        list[i].classList.add('active');
                    }
                }
            } else {
                value.classList.remove('active');
            }
        });
    }

    render(): React.ReactNode {
        return (
            <main className={'app'}>
                <Blog/>
            </main>
        );
    }
}

export default App;
