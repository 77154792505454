import React, { PureComponent } from 'react';
import BlogToc from './blog-toc/blog-toc';

import './blog-page.scss';

class BlogPage extends PureComponent {
    render(): React.ReactNode {
        return (
            <div className={'row mb-5'}>
                <BlogToc/>
                <article id={'single-post'} className={'postid-1 col-md-10 pt-4'}>
                    <h1 id={"post-title"}>How to set up the most popular local web server XAMPP from Apachefriends</h1>
                    <div className="text-muted font-weight-lighter mb-3">
                        <small>
                            <span>Published on </span>
                            <time dateTime="2019-01-31T20:16:07.209Z">Jan 31, 2019</time>
                            <span className="author"> by Joel Bladt</span>
                            <span className="devider px-1">•</span>
                            <span className="art">Average reading time are 5 minutes</span>
                        </small>
                    </div>
                    <p>You want to test your PHP scripts offline or a, for you themselves, interesting Content Management System offline on a local web server? You don't want to close a deal with a hosting provider like Bluehost, SiteGround or Dreamhost? Then is XAMPP from Apachefriends, which it works equally well on Linux, Mac and Windows really right for you. Here I want to explain you, how you setting up XAMPP so you can test your scripts offline on your local computer.</p>
                    <h2 id={"requirements-for-xampp"} className={"mb-3 pt-4"}>Requirements for XAMPP</h2>
                    <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet.</p>
                    <h2 id={"step-by-step-installation"} className={"mb-3 pt-4"}>Step by step installation</h2>
                    <p>Duis autem vel eum iriure dolor in hendrerit in vulputate velit esse molestie consequat, vel illum dolore eu feugiat nulla facilisis at vero eros et accumsan et iusto odio dignissim qui blandit praesent luptatum zzril delenit augue duis dolore te feugait nulla facilisi. Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat.</p>
                    <h2 id={"setting-up-the-local-web-server"} className={"mb-3 pt-4"}>Setting up the local web server</h2>
                    <p>Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat. Duis autem vel eum iriure dolor in hendrerit in vulputate velit esse molestie consequat, vel illum dolore eu feugiat nulla facilisis at vero eros et accumsan et iusto odio dignissim qui blandit praesent luptatum zzril delenit augue duis dolore te feugait nulla facilisi.</p>
                    <h2 id={"port-80-443-is-already-in-use"} className={"mb-3 pt-4"}>Troubleshooting: Port 80 / 443 is already in use</h2>
                    <p>Nam liber tempor cum soluta nobis eleifend option congue nihil imperdiet doming id quod mazim placerat facer possim assum. Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat.</p>
                    <h2 id={"security-check-before-allow-access"} className={"mb-3 pt-4"}>Security check before allowing access</h2>
                    <p>Duis autem vel eum iriure dolor in hendrerit in vulputate velit esse molestie consequat, vel illum dolore eu feugiat nulla facilisis.</p>
                    <h2 id={"step-by-step-update-upgrade-xampp"} className={"mb-3 pt-4"}>Step by step update / upgrade XAMPP</h2>
                    <h2 id={"my-personal-conclusion"} className={"mb-3 pt-4"}>My personal Conclusion</h2>
                </article>
            </div>
        );
    }
}

export default BlogPage;
